import React from "react";

const IconLogo = () => (
  <svg viewBox="0 0 248 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M59.1016 31.7618C58.0213 32.2681 56.8411 32.5257 55.648 32.5155C53.7059 32.5155 53.1168 31.7557 53.1168 29.7729V24.625H58.0513C58.3886 23.9506 58.5572 22.4736 58.5572 21.1247C58.5572 19.7311 58.3886 18.2968 58.0513 17.6224H53.1168V10.9529C49.7404 10.9529 46.492 11.7533 44.2553 13.1469V17.6163H42.0632C41.3461 19.4731 40.8809 22.3416 40.8809 24.6189H44.2553V31.323C44.2553 36.0483 45.5351 39.6928 52.0197 40.0991C56.162 40.3571 59.4348 39.4226 61.8198 38.0371C60.3043 36.4485 59.3799 34.3154 59.1016 31.7618Z"
      fill="currentColor"
    />
    <path
      d="M104.912 38.4313C100.693 40.1195 97.8733 40.1093 95.5533 40.1093C88.4653 40.1093 84.5403 35.7639 84.5403 28.8445C84.5403 20.9216 89.5623 16.7793 96.4797 16.7793C98.5112 16.7793 101.89 17.5492 104.981 19.658V25.9862C102.592 24.4402 100.53 23.9546 98.633 23.9546C95.5532 23.9546 93.5704 25.0517 93.5704 28.3001C93.5704 31.5485 95.6792 32.7308 98.759 32.7308C100.53 32.7308 103.267 31.7983 104.912 31.1239V38.4313Z"
      fill="currentColor"
    />
    <path
      d="M82.9721 31.9649C81.2839 32.6678 77.6901 33.5698 74.7546 33.5698C71.301 33.5698 69.9439 33.0213 69.5661 31.0812H81.3346C81.7803 29.661 82.0084 28.1816 82.0112 26.6931C82.0112 20.7022 78.974 16.7793 72.012 16.7793C65.2206 16.7793 60.5359 21.5026 60.5359 28.7186C60.5359 35.7212 64.7838 39.0793 72.3492 40.1093C75.5204 40.542 81.5683 40.1195 84.8025 37.1656L84.7496 37.0133C83.8091 35.6237 83.2098 33.9233 82.9721 31.9649ZM71.8434 22.7357C73.6575 22.7357 74.3747 23.9546 74.0374 25.6042H69.2695C69.3548 23.656 70.3238 22.7357 71.8434 22.7357Z"
      fill="currentColor"
    />
    <path
      d="M116.055 38.8011C115.212 39.2236 113.735 39.4755 111.625 39.4755C109.514 39.4755 108.039 39.2236 107.196 38.8011V9.0149C109.262 7.91991 112.173 7.32874 116.055 7.32874V12.8971C116.065 15.4923 115.793 18.0808 115.243 20.6169H115.751C117.016 18.045 119.168 16.7794 122.668 16.7794C125.705 16.7794 129.545 17.8337 129.545 24.9623V38.8011C128.702 39.2236 127.225 39.4755 125.116 39.4755C123.007 39.4755 121.53 39.2236 120.685 38.8011V26.7359C120.685 25.3016 120.222 24.5012 118.871 24.5012C117.605 24.5012 116.636 25.3869 116.045 26.484L116.055 38.8011Z"
      fill="currentColor"
    />
    <path
      d="M177.742 28.0847C177.742 35.9325 173.356 40.1093 166.311 40.1093C158.886 40.1093 154.835 36.1438 154.835 28.0847C154.835 20.9561 159.603 16.7793 166.311 16.7793C173.261 16.7793 177.742 20.7042 177.742 28.0847ZM166.311 33.3159C168.083 33.3159 168.674 31.4591 168.674 28.17C168.674 25.3422 167.861 23.5707 166.311 23.5707C164.666 23.5707 163.906 25.3015 163.906 28.17C163.896 31.4184 164.698 33.3159 166.301 33.3159H166.311Z"
      fill="currentColor"
    />
    <path
      d="M144.712 16.7793C148.072 16.8199 151.347 17.8455 154.13 19.7291L154.161 19.7697C153.107 21.366 152.422 23.1764 152.153 25.0699C150.766 24.4239 148.537 23.9506 146.768 23.9506C143.688 23.9506 141.705 25.0476 141.705 28.296C141.705 31.5444 143.814 32.7267 146.894 32.7267C148.744 32.6904 150.569 32.2908 152.265 31.5505C152.655 33.8177 153.413 35.707 154.522 37.2184C154.536 37.257 154.553 37.3078 154.553 37.3078C151.741 40.4018 145.273 40.1195 143.688 40.1093C136.6 40.0585 132.677 35.7639 132.677 28.8445C132.677 20.9216 137.697 16.7793 144.616 16.7793H144.712Z"
      fill="currentColor"
    />
    <path
      d="M193.257 9.0149C195.325 7.91991 198.237 7.32874 202.119 7.32874V38.411C200.514 39.2541 198.025 39.7193 195.537 39.7193L194.401 36.2271H193.895C193.261 37.9153 191.238 40.1094 187.394 40.1094C183.551 40.1094 179.209 37.9153 179.209 29.6877C179.209 21.6306 183.26 16.7794 189.672 16.7794C191.065 16.7794 192.246 16.948 193.257 17.4965V9.0149ZM193.343 24.4138C192.855 24.1852 192.321 24.0699 191.782 24.0766C189.503 24.0766 188.329 25.7648 188.329 28.6333C188.329 31.4185 189.426 32.5562 191.029 32.5562C191.46 32.5557 191.887 32.4698 192.285 32.3035C192.684 32.1372 193.045 31.8937 193.349 31.5871L193.343 24.4138Z"
      fill="currentColor"
    />
    <path
      d="M237.091 33.8644C238.398 33.8644 238.694 33.4845 238.694 32.936C238.694 30.9045 229.835 32.4728 229.835 24.4138C229.835 19.5625 232.829 16.7793 238.609 16.7793C241.014 16.7793 243.46 17.1998 245.274 18.1709C246.371 19.7738 247.005 22.7703 246.625 24.8749C244.643 23.7779 241.857 22.9754 239.875 22.9754C238.859 22.9754 238.656 23.272 238.656 23.6926C238.656 25.7241 247.641 24.2411 247.641 31.9629C247.641 38.0371 243.801 40.1052 237.601 40.1052C235.322 40.1052 232.791 39.8513 230.725 39.0488C229.459 37.1941 229.036 33.777 229.459 31.9629C231.438 33.064 234.432 33.8644 237.091 33.8644Z"
      fill="currentColor"
    />
    <path
      d="M226.948 32.2839C223.301 33.2347 222.617 33.5658 219.667 33.5658C216.213 33.5658 214.858 33.0173 214.479 31.0772H226.249C226.694 29.6569 226.921 28.1774 226.924 26.6891C226.924 20.6981 223.886 16.7753 216.924 16.7753C210.133 16.7753 205.45 21.4986 205.45 28.7145C205.45 35.7172 209.668 39.317 217.262 40.1053C220.057 40.3937 226.011 40.1195 228.175 38.0737C227.33 36.4201 226.92 34.1285 226.948 32.2839ZM216.748 22.7358C218.562 22.7358 219.279 23.9547 218.942 25.6043H214.174C214.267 23.656 215.238 22.7358 216.748 22.7358Z"
      fill="currentColor"
    />
    <path
      d="M27.2696 15.7229C27.9532 16.0797 28.5259 16.6169 28.9256 17.2763C29.3253 17.9356 29.5367 18.6919 29.5368 19.463V33.9904L8.54302 23.0202C6.2684 21.83 4.36298 20.04 3.03331 17.844C1.70363 15.6481 1.00045 13.13 1 10.5629V2L27.2696 15.7229Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.71325"
      strokeMiterlimit="10"
    />
    <path
      d="M1 57.3346L27.2798 43.6116C27.9633 43.2549 28.5361 42.7176 28.9358 42.0583C29.3355 41.3989 29.5468 40.6426 29.547 39.8716V33.9802L21.3376 29.6937L21.2685 29.6571L8.55319 36.2961C6.27392 37.4863 4.36462 39.2786 3.03291 41.4782C1.70121 43.6778 0.998086 46.2004 1 48.7717V57.3346Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.71325"
      strokeMiterlimit="10"
    />
  </svg>
);

export default IconLogo;
